import "./links.css"
import sampleImage from "./../../components/images/PFP.png"
import { useEffect, useState } from "react"
import RRLogoNoBK from "./../../components/images/RRLogoNoBK.png"
import OF from "./../../components/images/of.png"
import Fansly from "./../../components/images/fansly.png"
import Twitter from "./../../components/images/twitter2.png"
import Snap from "./../../components/images/snap.png"
import Insta from "./../../components/images/Insta2.png"
import TikTok from "./../../components/images/tiktok.png"
import Identitties from "./../../components/images/logo2.png"


const LinksPage = () => {

    const [showAgeVerify, SetShowAgeVerify] = useState("none")
    const [showAgeVerifyFansly, SetShowAgeVerifyFansly] = useState("none")

    function instaRoute(){
        window.open(
            'https://www.instagram.com/raerockhold/?hl=en',
            '_blank'
        );
    }

    function instaRouteMb(){
        window.open(
            'https://www.instagram.com/raerockhold/?hl=en'
        );
    }

    function tikTokRoute(){
        window.open(
            'https://www.tiktok.com/@raerockhold',
            '_blank'
        );
    }

    function tikTokRouteMb(){
        window.open(
            'https://www.tiktok.com/@raerockhold'
        );
    }

    function twitterRoute(){
        window.open(
            'https://twitter.com/RaeRockhold?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
            '_blank'
        );
    }

    function twitterRouteMb(){
        window.open(
            'https://twitter.com/RaeRockhold?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor'
        );
    }

    function snapRoute(){
        window.open(
            'https://www.snapchat.com/add/raerockhold',
            '_blank'
        );
    }

    function snapRouteMb(){
        window.open(
            'https://www.snapchat.com/add/raerockhold'
        );
    }

    function identittiesRoute(){
        window.open(
            'https://www.identitties.com',
            '_blank'
        );
    }

    function identittiesRouteMb(){
        window.open(
            'https://www.identitties.com'
        );
    }

    function FanslyRoute(){
        acceptedAgeWarningFansly()
    }   

    function acceptedAgeWarningFansly(){
        SetShowAgeVerifyFansly("block")
    }

    function closeWindowFansly(){
        SetShowAgeVerifyFansly("none")
    }

    function continueLinkFansly(){
        SetShowAgeVerifyFansly("none")
        window.open(
            'https://fans.ly/raerockhold',
            '_blank'
        );   
    }

    function FanslyRouteMb(){
        acceptedAgeWarningFanslyMb()
    }   

    function acceptedAgeWarningFanslyMb(){
        SetShowAgeVerifyFansly("block")
    }

    function closeWindowFanslyMb(){
        SetShowAgeVerifyFansly("none")
    }

    function continueLinkFanslyMb(){
        SetShowAgeVerifyFansly("none")
        window.open(
            'https://fans.ly/raerockhold'
        );   
    }

    function OFRoute(){
        acceptedAgeWarning()
    }   

    function acceptedAgeWarning(){
        SetShowAgeVerify("block")
    }

    function closeWindow(){
        SetShowAgeVerify("none")
    }

    function continueLink(){
        SetShowAgeVerify("none")
        window.open(
            'https://onlyfans.com/raerockhold',
            '_blank'
        );   
    }
    

    function OFRouteMb(){
        acceptedAgeWarningMb()
    }   

    function acceptedAgeWarningMb(){
        SetShowAgeVerify("block")
    }

    function closeWindowMb(){
        SetShowAgeVerify("none")
    }

    function continueLinkMb(){
        SetShowAgeVerify("none")
        window.open(
            'https://onlyfans.com/raerockhold'
        );   
    }
    

    return(
        <div>
            <div className="body-container">
                <div className="main-container">
                    <div className="inner-container">    
                        <div className="logo">
                            <img src={RRLogoNoBK}/>
                        </div>
                        <div className="ageVerify" style={{display:showAgeVerify}}>
                            <h1>18+ Age Warning</h1>
                            <p>This link may contain content that is not appropriate for all audiences.</p>
                            <div>
                                <button className="declineButton" onClick={closeWindow}>Nevermind</button>
                                <button className="acceptButton" onClick={continueLink}>Continue</button>
                            </div>
                        </div>    
                        <div className="ageVerify" style={{display:showAgeVerifyFansly}}>
                            <h1>18+ Age Warning</h1>
                            <p>This link may contain content that is not appropriate for all audiences.</p>
                            <div>
                                <button className="declineButton" onClick={closeWindowFansly}>Nevermind</button>
                                <button className="acceptButton" onClick={continueLinkFansly}>Continue</button>
                            </div>
                        </div>                 
                        <div className="top-section">
                            <div className="profile-picture-outline">
                                <div className="profile-picture">
                                    <img src={sampleImage}/>
                                </div>
                            </div>
                            <div className="Name">
                                <h1>Rae Rockhold</h1>                            
                            </div>
                            <div className="bio">
                                <p>Welcome to all things Rae &#9825;</p>                            
                            </div>
                            <div className="socials">
                                <div className="social-container2">
                                    <img src={OF} onClick={OFRoute}/>
                                </div>
                                <div className="social-container2">
                                    <img src={Fansly} onClick={FanslyRoute}/>
                                </div>
                                <div className="social-container2">
                                    <img src={Insta}  onClick={instaRoute}/>
                                </div>
                                <div className="social-container2">
                                    <img src={Twitter} onClick={twitterRoute}/>
                                </div>
                                <div className="social-container-tiktok">
                                    <img src={TikTok} onClick={tikTokRoute}/>
                                </div>
                                <div className="social-container2">
                                    <img src={Snap} onClick={snapRoute}/>
                                </div>
                                <div className="social-container-identitties">
                                    <img src={Identitties} onClick={identittiesRoute}/>
                                </div>
                            </div> 
                        </div>
                        <div className="picture-link-section">
                            <div className="card-body">
                                <div className="card-inner-body" 
                                    style={{backgroundImage: "url(https://raelinkimages.s3.us-west-1.amazonaws.com/Fansly2.jpg)"}}
                                    onClick={OFRoute}>
                                    <h2>OnlyFans</h2>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="card-inner-body" 
                                   style={{backgroundImage: "url(https://raelinkimages.s3.us-west-1.amazonaws.com/OFRotate.jpg)"}}
                                    onClick={FanslyRoute}>
                                    <h2>Fansly</h2>
                                </div>
                            </div>
                        </div>
                        <div className="footer-section">
                        </div>
                    </div>
                </div>    
            </div>
            <div className="body-container-mobile">
                <div className="main-container-mobile">
                    <div className="inner-container">    
                        <div className="logo">
                            <img src={RRLogoNoBK}/>
                        </div>
                        <div className="ageVerify" style={{display:showAgeVerify}}>
                            <h1>18+ Age Warning</h1>
                            <p>This link may contain content that is not appropriate for all audiences.</p>
                            <div>
                                <button className="declineButton" onClick={closeWindow}>Nevermind</button>
                                <button className="acceptButton" onClick={continueLink}>Continue</button>
                            </div>
                        </div>   
                        <div className="ageVerify" style={{display:showAgeVerifyFansly}}>
                            <h1>18+ Age Warning</h1>
                            <p>This link may contain content that is not appropriate for all audiences.</p>
                            <div>
                                <button className="declineButton" onClick={closeWindowFanslyMb}>Nevermind</button>
                                <button className="acceptButton" onClick={continueLinkFanslyMb}>Continue</button>
                            </div>
                        </div> 
                        <div className="top-section">
                            <div className="profile-picture-outline">
                                <div className="profile-picture">
                                    <img src={sampleImage}/>
                                </div>
                            </div>
                            <div className="Name">
                                <h1>Rae Rockhold</h1>                            
                            </div>
                            <div className="bio">
                                <p>Welcome to all things Rae &#9825;</p>                            
                            </div>
                            <div className="socials">
                                <div className="social-container">
                                    <img src={OF} onClick={OFRouteMb}/>
                                </div>
                                <div className="social-container2">
                                    <img src={Fansly} onClick={FanslyRouteMb}/>
                                </div>
                                <div className="social-container">
                                    <img src={Insta} onClick={instaRouteMb}/>
                                </div>
                                <div className="social-container">
                                    <img src={Twitter} onClick={twitterRouteMb}/>
                                </div>
                                <div className="social-container-tiktok">
                                    <img src={TikTok} onClick={tikTokRouteMb}/>
                                </div>
                                <div className="social-container2">
                                    <img src={Snap} onClick={snapRouteMb}/>
                                </div>
                                <div className="social-container-identitties">
                                    <img src={Identitties} onClick={identittiesRouteMb}/>
                                </div>
                            </div> 
                        </div>
                        <div className="picture-link-section">
                            <div className="card-body">
                                <div className="card-inner-body" 
                                    style={{backgroundImage: "url(https://raelinkimages.s3.us-west-1.amazonaws.com/Fansly2.jpg)"}}
                                    onClick={OFRoute}>
                                    <h2>OnlyFans</h2>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="card-inner-body" 
                                    style={{backgroundImage: "url(https://raelinkimages.s3.us-west-1.amazonaws.com/OFRotateMobile.jpg)"}}
                                    onClick={FanslyRoute}>
                                    <h2>Fansly</h2>
                                </div>
                            </div>
                        </div>
                        <div className="footer-section">
                            {/* <h3>&#9825; All the links you want are above &#9825;</h3> */}
                        </div>
                    </div>
                </div>    
            </div>
        </div>
    )
}

export default LinksPage